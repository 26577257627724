import * as React from 'react';
import { Link, HeadFC } from 'gatsby';

const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
};

const paragraphStyles = {
  marginBottom: 48,
};

const NotFoundPage = () => {
  return (
    <main
      style={{
        color: '#232129',
        padding: '96px',
        fontFamily: '-apple-system, Roboto, sans-serif, serif',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <h1 style={headingStyles}>Página não encontrada</h1>
      <p style={paragraphStyles}>
        Erro ao encontrar a página, página selecionada não existe em nossa
        plataforma. Por favor, clique embaixo para voltar a página principal.
      </p>
      <Link
        to="/"
        style={{
          fontSize: '2rem',
          color: 'dodgerblue',
        }}
      >
        Página principal
      </Link>
    </main>
  );
};

export default NotFoundPage;

export const Head: HeadFC = () => <title>Página não encontrada</title>;
